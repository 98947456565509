export const TEST_STEP_STATUS_MAP = Object.freeze(new Map([
  [0, {
    id: 0,
    name: "pending",
    text: "Pending",
    color: "pending",
    icon: "mdi-timer-sand",
  }],
  [1, {
    id: 1,
    name: "ok",
    text: "OK",
    color: "ok",
    icon: "mdi-check-circle",
  }],
  [2, {
    id: 2,
    name: "resultWarning",
    text: "Result Warning",
    color: "testStatusWarning",
    icon: "mdi-alert",
  }],
  [3, {
    id: 3,
    name: "resultError",
    text: "Result Error",
    color: "testStatusError",
    icon: "mdi-alert-box",
  }],
  [7, {
    id: 7,
    name: "processingWarning",
    text: "Processing Warning",
    color: "testStatusRuntimeWarning",
    icon: "mdi-alert",
  }],
  [8, {
    id: 8,
    name: "processingError",
    text: "Processing Error",
    color: "testStatusRuntimeError",
    icon: "mdi-alert-box",
  }],
]));

export const TEST_STEP_STATUS_MAP_SHORT_TXT = new Map(Array.from(TEST_STEP_STATUS_MAP).map(([id, t]) => ([id, { ...t, text: t.text.replace(/Processing/i, "Proc") }])));
